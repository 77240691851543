<template>
  <div>
    <Dialog @hide="dialogReview = false; $emit('close')" :draggable="false" v-model:visible="showDialogLocal" :style="{width: '70%', transition: 'left 0.5s cubic-bezier(0.51, -0.01, 0, 0.94) 0s', position: 'absolute', left: dialogReview ? '1%' : '15%'}" header="Facture fournisseur" :modal="true" class="p-fluid">
      <div class="mt-5 align-items-center mb-3 flex" style="gap: 3rem;">
        <div class="flex">
          <label for="refacturable" class="mr-5">Refacturable client</label>
          <div>
            <Checkbox id="refacturable" :disabled="facturefournisseur && facturefournisseur.independentStatus !== 'draft'" :binary="true" v-model="refacturable"/>
          </div>
        </div>
        <div v-if="refacturable">
          <div class="flex align-items-center" style="gap: 2rem;">
            <label for="mission">Mission</label>
            <Dropdown id="mission" :disabled="facturefournisseur && facturefournisseur?.independentStatus !== 'draft'" :option-label="(val) => val.clientContract.name" v-if="missions" v-model="mission" :options="missions"/>
          </div>
        </div>
      </div>
      <TreeTable :value="ocrResult" :expanded-keys="{0: true}">
        <template #header>
          Facture fournisseur
        </template>
        <Column class="flex align-items-center" field="nature" header="Nature" :expander="true">
          <template #body="value">
            <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1">
<!--              <Dropdown :disabled="facturefournisseur && facturefournisseur.independentStatus !== 'draft'" v-model="value.node.nature" :options="natures"></Dropdown>-->
              <InputText @keydown="(event) => { event.keyCode === 32 || event.key === ' ' ? value.node.nature += ' ' : null }" :disabled="facturefournisseur && facturefournisseur.independentStatus !== 'draft'" v-model="value.node.nature"></InputText>
            </div>
            <div v-else>
              {{ value.node.data.nature }}
            </div>
          </template>
        </Column>
        <Column field="amountexvat" header="Prix HT">
          <template #body="value">
            <InputNumber disabled v-if="!value.node.editable && value.node.key.toString().indexOf('-') === -1 || facturefournisseur && facturefournisseur.independentStatus !== 'draft'" v-model="value.node.data.amountexvat" locale="fr-FR" mode="currency" currency="EUR"></InputNumber>
            <InputNumber v-else @input="(event) => updateSomme('amountexvat', value.node.key, event)" v-model="value.node.data.amountexvat" locale="fr-FR" mode="currency" currency="EUR" />
          </template>
        </Column>
        <Column field="vatamount" header="TVA">
          <template #body="value">
            <InputNumber disabled v-if="(!value.node.editable && value.node.key.toString().indexOf('-') === -1) || facturefournisseur && facturefournisseur.independentStatus !== 'draft'" v-model="value.node.data.vatamount" locale="fr-FR" mode="currency" currency="EUR"></InputNumber>
            <InputNumber v-else @input="(event) => updateSomme('vatamount', value.node.key, event)" v-model="value.node.data.vatamount" locale="fr-FR" mode="currency" currency="EUR" />
          </template>
        </Column>
        <Column field="amount" header="Prix TTC">
          <template #body="value">
            <InputNumber disabled :model-value="value.node.data.amount" locale="fr-FR" mode="currency" currency="EUR" />
          </template>
        </Column>
        <Column field="date" header="Date">
          <template #body="value">
            <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1">
              <Calendar :disabled="facturefournisseur && facturefournisseur.independentStatus !== 'draft'" :showIcon="true" v-model="value.node.data.date" date-format="dd/mm/yy"></Calendar>
            </div>
          </template>
        </Column>
        <Column field="file" header="Actions">
          <template #body="value">
            <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1">
              <Button type="button" icon="pi pi-eye" class="p-button-success" v-if="value.node.justificatif" @click="reviewFacturefournisseur(value.node.justificatif)" style="margin-right: .5em"></Button>
              <Button type="button" icon="pi pi-upload" class="p-button-info" v-if="hasAccess($route.name, true) && !facturefournisseur || facturefournisseur.independentStatus !== 'validated'" @click="clickAddJustificatif" style="margin-right: .5em"></Button>
              <FileUpload ref="upload" style="display: none" mode="basic" auto custom-upload @uploader="onUploadJustificatif" accept="image/png, image/jpeg, application/pdf" :maxFileSize="10000000" />
            </div>
          </template>
        </Column>
      </TreeTable>
      <template v-if="hasAccess($route.name, true)" #footer>
        <Button v-if="facturefournisseur && facturefournisseur.independentStatus !== 'validated'" label="Supprimer" icon="pi pi-trash" class="p-button-text" @click="removeFacturefournisseur"/>
        <Button v-if="!facturefournisseur || facturefournisseur.independentStatus !== 'validated'" label="Enregistrer le brouillon" icon="pi pi-check" class="p-button-text" @click="saveFacturefournisseur" />
        <Button v-if="!facturefournisseur || facturefournisseur.independentStatus !== 'validated'" label="Enregistrer et envoyer" icon="pi pi-check" class="p-button-text" @click="saveAndSendFacturefournisseur" />
      </template>
    </Dialog>
    <Dialog v-model:visible="dialogReview" position="right" :style="{width: '25%'}" dismissable-mask class="p-fluid">
      <iframe v-if="imgSrc.includes('application/pdf')" style="width: 100%; height: 50vh" :src="imgSrc + '#toolbar=0&navpanes=0&scrollbar=0'" />
      <img v-else style="width: 100%" :src="imgSrc" />
    </Dialog>
  </div>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";
import {mapGetters} from "vuex";
import { hasAccess } from "@/utils/Misc";

export default {
  name: "DialogFactureFournisseurPorte",
  emits: ["close", "reload"],
  props: ['dialog', 'ocr', 'file', 'facturefournisseur', 'missions', 'independents'],
  data () {
    return {
      hasAccess: hasAccess,
      showDialogLocal: this.dialog,
      dialogReview: false,
      refacturable: false,
      ocrResult: null,
      natures: ['Indemnités kilométriques', 'Achats fournitures', 'Culture', 'Loisirs et sport', 'Vacances', 'PERCO'],
      imgSrc: null,
      mission: null,
      justificatifLocal: null,
      coucou: ''
    }
  },
  watch: {
    dialog (val) {
      this.showDialogLocal = val
    },
  },
  computed: {
    ...mapGetters({
      independent: 'independent/getIndependent',
      facturefournisseurBD: 'facturefournisseur/getFacturefournisseur'
    })
  },
  async created () {
    this.ocrResult = []
    if (this.facturefournisseur) {
      this.refacturable = this.facturefournisseur.refacturable
      this.mission = this.missions.find(mission => mission.id === this.facturefournisseur.mission?.id)
      const data = { amount: this.facturefournisseur.ttc / 100, amountexvat: this.facturefournisseur.ht / 100, vatamount: this.facturefournisseur.tva / 100, date: this.facturefournisseur.date }
      this.ocrResult.push({ id: this.facturefournisseur.id, 'key': '0', data: data, nature: this.facturefournisseur.nature, children: [], 'justificatif': this.facturefournisseur.justificatif, editable: !this.facturefournisseur.item || this.facturefournisseur.item.length === 0 })
      this.facturefournisseur.item.forEach((i, index) => {
        this.ocrResult[0].children.push({ key: 0 + '-' + index, data: { nature: i.nom, amount: i.ttc / 100, amountexvat: i.ht / 100, vatamount: i.tva / 100 } })
      })
    } else if (this.ocr) {
      const data = { amount: this.ocr.data.amount / 100, amountexvat: this.ocr.data.amountexvat / 100, vatamount: this.ocr.data.vatamount / 100, date: new Date(this.ocr.data.date) }
      const key = this.ocrResult.length
      this.ocrResult.push({ 'key': key, data: data, nature: '', children: [], 'justificatif': this.file.files[0], editable: true })

      this.ocr.data.vatitems.forEach((vat, index) => {
        this.ocrResult[key].editable = false
        this.ocrResult[key].children.push({ key: key + '-' + index, data: { nature: 'TVA ' + (index + 1), amount: vat.amount_incl_vat / 100, amountexvat: vat.amount_excl_vat / 100, vatamount: vat.amount / 100 } })
      })
      this.reviewFacturefournisseur(this.file.files[0])
    } else {
      const data = { amount: 0, amountexvat: 0, vatamount: 0, date: new Date() }
      this.ocrResult.push({ 'key': '0', data: data, nature: 'q s', children: [], 'justificatif': null, editable: true })
    }
  },
  methods: {
    onUploadJustificatif (file) {
      this.justificatifLocal = file.files[0]
      this.ocrResult[0].justificatif = this.justificatifLocal
      this.reviewFacturefournisseur(this.justificatifLocal)
    },
    clickAddJustificatif () {
      this.$refs.upload.choose()
    },
    async removeFacturefournisseur () {
      this.$confirm.require({
        header: 'Supprimer facture fournisseur',
        message: 'Êtes-vous sûr de vouloir supprimer cette facture fournisseur ?',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$store.state.misc.loading = true
          await this.$store.dispatch('facturefournisseur/deleteFacturefournisseur', this.facturefournisseur)
          this.$store.state.misc.loading = false
          this.$toast.add({severity:'success', summary:'Succès', detail:'Facture fournisseur supprimée', life: 3000});
          this.$emit('reload')
          this.$emit('close')
          this.$confirm.close()
        },
        reject: () => {

        }
      })
    },
    checkValid () {
      if (this.refacturable && !this.mission) {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez sélectionner une mission', life: 3000})
        return false
      }
      return true
    },
    async saveAndSendFacturefournisseur () {

      if (!this.checkValid()) return

      this.$store.state.misc.loading = true
      await this.saveFacturefournisseur(false, 'validated')
      this.$store.state.misc.loading = false
      this.$toast.add({severity:'success', summary:'Succès', detail:'Facture fournisseur sauvegardée et envoyée', life: 3000});
      this.$emit('reload')
      this.$emit('close')
    },
    async saveFacturefournisseur (quitAfter = true, status = 'draft') {

      if (!this.checkValid()) return

      if (!this.refacturable) this.mission = null

      this.$store.state.misc.loading = true

      const facturefournisseur = {
        refacturable: this.refacturable,
        mission: this.mission?.id,
        ht: Math.round(this.ocrResult[0].data.amountexvat * 100),
        tva: Math.round(this.ocrResult[0].data.vatamount * 100),
        ttc: Math.round(this.ocrResult[0].data.amount * 100),
        nature: this.ocrResult[0].nature,
        date: this.ocrResult[0].data.date,
        independent: this.independent.id,
        item: [],
        independentStatus: status
      }

      if (this.file?.files[0] || this.justificatifLocal) {
        const f = this.file?.files[0]?? this.justificatifLocal
        const form = new FormData()
        form.append('filename', f.name)
        form.append('file', f, f.name)
        const uploadedFile = await this.$store.dispatch('file/uploadFile', form)
        facturefournisseur['justificatif'] = uploadedFile.id
      }

      this.ocrResult[0].children.forEach((child) => {
        facturefournisseur.item.push({
          nom: child.data.nature,
          ht: Math.round(child.data.amountexvat * 100),
          tva: Math.round(child.data.vatamount * 100),
          ttc: Math.round(child.data.amount * 100)
        })
      })

      if (this.facturefournisseur?.id) {
        facturefournisseur['id'] = this.facturefournisseur.id
        await this.$store.dispatch('facturefournisseur/updateFacturefournisseur', facturefournisseur)
      } else {
        await this.$store.dispatch('facturefournisseur/createFacturefournisseur', facturefournisseur)
      }

      if (quitAfter) {
        this.$store.state.misc.loading = false
        this.$toast.add({severity:'success', summary:'Succès', detail:'Facture fournisseur sauvegardée', life: 3000});
        this.$emit('reload')
        this.$emit('close')
      }
    },
    updateSomme (attribute, key, event) {
      if (key.indexOf('-') !== -1) {
        const parent = this.ocrResult[key.split('-')[0]]
        parent.children[key.split('-')[1]].data[attribute] = event.value
        let somme = 0
        parent.children.forEach((child) => {
          somme = (somme * 100 + child.data[attribute] * 100) / 100
        })
        parent.data[attribute] = somme
      } else {
        const parent = this.ocrResult[key]
        parent.data[attribute] = event.value
      }
      this.updateTTCs()
    },
    updateTTCs () {
      this.ocrResult.forEach((parent) => {
        if (parent.children.length) {
          parent.children.forEach((child) => {
            child.data['amount'] = (child.data['amountexvat'] * 100 + child.data['vatamount'] * 100) / 100
          })
        }
        parent.data['amount'] = (parent.data['amountexvat'] * 100 + parent.data['vatamount'] * 100) / 100
      })
    },
    formatEuro (value) {
      return getEuroFormat(value)
    },
    async reviewFacturefournisseur (file) {
      this.$store.state.misc.loading = true
      let transformToPdf = true
      if (typeof file === 'string') {
        const result = await this.$store.dispatch('file/downloadFile', {id: file})
        if (result.mimetype.includes('image')) transformToPdf = false
        file = new Blob([new Uint8Array(result.data.data)])
      }
      const reader = new FileReader()
      reader.onload = () => {
        this.imgSrc = reader.result
        if (transformToPdf) {
          this.imgSrc = this.imgSrc.replace('application/octet-stream', 'application/pdf')
        }
        this.dialogReview = true
        this.$store.state.misc.loading = false
      }
      reader.readAsDataURL(file)

    },
  }
}
</script>

<style>
.dialog-content-loading {
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
::v-deep(.p-dialog) {
  box-shadow: none !important;
}
</style>
