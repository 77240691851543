<template>
  <div>
    <Dialog @hide="dialogReview = false; $emit('close')" :draggable="false" v-model:visible="showDialogLocal" :style="{width: '70%', transition: 'left 0.5s cubic-bezier(0.51, -0.01, 0, 0.94) 0s', position: 'absolute', left: dialogReview ? '1%' : '15%'}" header="Dépense" :modal="true" class="p-fluid">
      <div class="flex mt-5 mb-3 align-items-center" v-if="canRefacture">
        <div class="flex mr-5">
          <label for="refacturable" class="mr-4 font-bold">Refacturable client</label>
          <div>
            <Checkbox id="refacturable" :disabled="depense?.ndf && depense.ndf.status !== 'validate'" :binary="true" v-model="refacturable"/>
          </div>
        </div>
        <div class="flex align-items-center" v-if="refacturable">
          <label for="mission" class="mr-3">Mission</label>
          <Dropdown id="mission" :disabled="depense?.ndf && depense.ndf.status !== 'validate'" :option-label="(val) => val.clientContract.name" v-if="missions" v-model="mission" :options="missions">
          </Dropdown>
        </div>
      </div>
      <TreeTable :value="ocrResult">
        <template #header>
          Dépense
        </template>
        <Column class="flex align-items-center" field="nature" header="Nature" :expander="true">
          <template #body="value">
            <i v-if="value.node.nature && value.node.nature?.info" v-tooltip.bottom="value.node.nature?.info" class="pi pi-info-circle mr-2 text-xl"></i>
            <div class="flex align-items-center">
              <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1">
                <Dropdown style="max-width: 8vw" :disabled="depense?.ndf && depense.ndf.status !== 'validate'" v-model="value.node.nature" :options="natures" option-label="label" option-group-label="label" option-group-children="items"></Dropdown>
              </div>
              <div v-else style="max-width: 8vw">
                {{ value.node.data.nature }}
              </div>
            </div>
          </template>
        </Column>
        <Column field="amountexvat" header="Prix HT">
          <template #body="value">
            <InputNumber disabled v-if="!value.node.editable && value.node.key.toString().indexOf('-') === -1 || (depense?.ndf && depense.ndf.status !== 'validate')" v-model="value.node.data.amountexvat" locale="fr-FR" mode="currency" currency="EUR"></InputNumber>
            <InputNumber v-else @input="(event) => updateSomme('amountexvat', value.node.key, event)" v-model="value.node.data.amountexvat" locale="fr-FR" mode="currency" currency="EUR" />
          </template>
        </Column>
        <Column field="vatamount" header="TVA">
          <template #body="value">
            <InputNumber disabled v-if="(!value.node.editable && value.node.key.toString().indexOf('-') === -1) || (depense?.ndf && depense.ndf.status !== 'validate')" v-model="value.node.data.vatamount" locale="fr-FR" mode="currency" currency="EUR"></InputNumber>
            <InputNumber v-else @input="(event) => updateSomme('vatamount', value.node.key, event)" v-model="value.node.data.vatamount" locale="fr-FR" mode="currency" currency="EUR" />
          </template>
        </Column>
        <Column field="amount" header="Prix TTC">
          <template #body="value">
            <InputNumber disabled :model-value="value.node.data.amount" locale="fr-FR" mode="currency" currency="EUR" />
          </template>
        </Column>
        <Column field="date" header="Date">
          <template #body="value">
            <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1">
              <Calendar :disabled="depense?.ndf && depense.ndf.status !== 'validate'" :showIcon="true" v-model="value.node.data.date" date-format="dd/mm/yy"></Calendar>
            </div>
          </template>
        </Column>
        <Column field="file" header="Actions">
          <template #body="value">
            <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1">
              <Button type="button" icon="pi pi-eye" class="p-button-success" v-if="value.node.justificatif" @click="reviewDepense(value.node.justificatif)" style="margin-right: .5em"></Button>
              <Button type="button" icon="pi pi-upload" class="p-button-info" v-if="hasAccess($route.name, true) && !depense?.ndf || depense?.ndf.status === 'validate'" @click="clickAddJustificatif" style="margin-right: .5em"></Button>
              <FileUpload ref="upload" style="display: none" mode="basic" auto custom-upload @uploader="onUploadJustificatif" accept="image/png, image/jpeg, application/pdf" :maxFileSize="10000000" />
              <Button v-tooltip.top="'Commentaires'" type="button" icon="pi pi-info" class="p-button-success" @click="clickCommentairesDepense(value.node)"></Button>
            </div>
          </template>
        </Column>
      </TreeTable>
      <template #footer v-if="hasAccess($route.name, true) && !depense?.ndf || depense?.ndf.status === 'validate'">
        <Button v-if="depense" label="Supprimer la dépense" icon="pi pi-trash" class="p-button-text" @click="removeDepense"/>
<!--        <Button label="Enregistrer en brouillon" icon="pi pi-check" class="p-button-text" @click="saveDepense" />-->
        <Button v-if="!(depense && depense.ndf)" label="Affecter à une note de frais" icon="pi pi-check" class="p-button-text" @click="saveAndAssignDepense" />
      </template>
    </Dialog>
    <Dialog header="Commentaires" v-model:visible="dialogCommentaires" :style="{width: '25%'}" :closable="false" :modal="true">
      <div class="p-float-label mb-4 mt-4">
        <Textarea id="commentairePorte" style="width: 100%; border-radius: 10px" :autoResize="true" rows="3" v-model="commentaire"></Textarea>
        <label for="commentairePorte">Commentaire porté</label>
      </div>
      <template #footer>
        <Button label="Annuler" icon="pi pi-times" class="p-button-danger" @click="dialogCommentaires = false; commentaire = ''" autofocus />
        <Button label="Confirmer" icon="pi pi-check" @click="dialogCommentaires = false" autofocus />
      </template>
    </Dialog>
    <Dialog v-model:visible="dialogReview" position="right" :style="{width: '25%'}" dismissable-mask class="p-fluid">
      <iframe v-if="imgSrc.includes('application/pdf')" style="width: 100%; height: 50vh" :src="imgSrc + '#toolbar=0&navpanes=0&scrollbar=0'" />
      <img v-else style="width: 100%" :src="imgSrc" />
    </Dialog>
  </div>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";
import {mapGetters, mapState} from "vuex";
import { hasAccess } from "@/utils/Misc";

export default {
  name: "DialogDepense",
  emits: ["close", "reload"],
  props: ['dialog', 'ocr', 'file', 'depense', 'missions', 'type'],
  data () {
    return {
      hasAccess: hasAccess,
      showDialogLocal: this.dialog,
      dialogReview: false,
      dialogCommentaires: false,
      refacturable: false,
      ocrResult: null,
      natures: [],
      imgSrc: null,
      mission: null,
      justificatifLocal: null,
      canRefacture: false,
      commentaire: ''
    }
  },
  watch: {
    dialog (val) {
      this.showDialogLocal = val
    },
    refacturable (val) {
      if (!val) {
        this.mission = null
      }
    }
  },
  computed: {
    ...mapGetters({
      independent: 'independent/getIndependent',
      depenseBD: 'depense/getDepense',
      depenses: 'depense/getDepenses'
    }),
    ...mapState({
      settingsNdf: state => state.misc.settings.NDF,
      constants: state => state.constants,
    })
  },
  created () {
    this.canRefacture = this.type === this.constants.NDF_TYPE.NDF
    if (this.type === this.constants.NDF_TYPE.NDF) {
      this.natures = [
        {
          label: 'Notes de frais',
          items: this.settingsNdf.categoriesNdf.filter(cndf => cndf.enabled)
        }
      ]
    } else if (this.type === this.constants.NDF_TYPE.SOCIAL_MEASURES) {
      this.natures = [
        {
          label: 'Dispositifs sociaux',
          items: this.settingsNdf.categoriesDs.filter(cds => cds.enabled)
        }
      ]
    }
    this.ocrResult = []
    if (this.depense) {
      this.refacturable = this.depense.refacturable
      this.mission = this.missions.find(mission => mission.id === this.depense.mission?.id)
      const data = { amount: this.depense.ttc / 100, amountexvat: this.depense.ht / 100, vatamount: this.depense.tva / 100, date: this.depense.date }
      this.ocrResult.push({ id: this.depense.id, 'key': '0', data: data, nature: this.depense.nature, children: [], 'justificatif': this.depense.justificatif, editable: this.depense.item.length === 0 })
      this.depense.item.forEach((i, index) => {
        this.ocrResult[0].children.push({ key: 0 + '-' + index, data: { nature: i.nom, amount: i.ttc / 100, amountexvat: i.ht / 100, vatamount: i.tva / 100 } })
      })
    } else if (this.ocr) {
      const data = { amount: this.ocr.data.amount / 100, amountexvat: this.ocr.data.amountexvat / 100, vatamount: this.ocr.data.vatamount / 100, date: new Date(this.ocr.data.date) }
      const key = this.ocrResult.length
      this.ocrResult.push({ 'key': key, data: data, nature: '', children: [], 'justificatif': this.file.files[0], editable: true })

      this.ocr.data.vatitems.forEach((vat, index) => {
        this.ocrResult[key].editable = false
        this.ocrResult[key].children.push({ key: key + '-' + index, data: { nature: 'TVA ' + (index + 1), amount: vat.amount_incl_vat / 100, amountexvat: vat.amount_excl_vat / 100, vatamount: vat.amount / 100 } })
      })
      this.reviewDepense(this.file.files[0])
    } else {
      const data = { amount: 0, amountexvat: 0, vatamount: 0, date: new Date() }
      this.ocrResult.push({ 'key': '0', data: data, nature: '', children: [], 'justificatif': this.file?.files[0], editable: true })
    }
  },
  methods: {
    clickCommentairesDepense () {
      this.dialogCommentaires = true
    },
    onUploadJustificatif (file) {
      this.justificatifLocal = file.files[0]
      this.ocrResult[0].justificatif = this.justificatifLocal
      this.reviewDepense(this.justificatifLocal)
    },
    clickAddJustificatif () {
      this.$refs.upload.choose()
    },
    async saveAndAssignDepense () {
      if (!this.ocrResult[0].nature) {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez sélectionner une nature', life: 3000})
        return
      }
      if (this.ocrResult[0].data.amount <= 0) {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Le total doit être supérieur à 0 €', life: 3000})
        return
      }
      let ok = true
      if (this.depenses.find(depense => {
        return depense.ttc === this.ocrResult[0].data.amount * 100 &&
            depense.nature.id === this.ocrResult[0].nature.id &&
            new Date(this.ocrResult[0].data.date).getDate() === new Date(depense.date).getDate() &&
            new Date(this.ocrResult[0].data.date).getMonth() === new Date(depense.date).getMonth() &&
            new Date(this.ocrResult[0].data.date).getFullYear() === new Date(depense.date).getFullYear() &&
            depense.mission?.id === this.mission?.id
      })) {
        ok = false
        this.$confirm.require({
          header: 'Dépense similaire',
          message: 'Une dépense similaire de même montant et mois a déjà été ajoutée.',
          acceptLabel: 'Continuer',
          rejectLabel: 'Annuler',
          accept: async () => {
            await this.saveDepense(false)
            const response = await this.$store.dispatch('depense/assignDepense', this.depenseBD)
            this.$store.state.misc.loading = false
            if (response.showNotification === true) {
              this.$toast.add({
                severity: 'info',
                summary: 'Attention',
                detail: `Le seuil de dépenses pour ce type a été dépassé pour cette année : ${response.totalYearly / 100}€ / ${response.maxAmount / 100}€ maximum`,
                life: 10000
              });
            }
            this.$toast.add({
              severity: 'success',
              summary: 'Succès',
              detail: 'Dépense sauvegardée et assignée',
              life: 3000
            });
            this.$emit('reload')
            this.$emit('close')
          },
          reject: () => {
            ok = false
          }
        })
      }
      if (ok) {
        await this.saveDepense(false)
        const response = await this.$store.dispatch('depense/assignDepense', this.depenseBD)
        this.$store.state.misc.loading = false
        if (response.showNotification === true) {
          this.$toast.add({severity:'info', summary:'Attention', detail:`Le seuil de dépenses pour ce type a été dépassé pour cette année : ${response.totalYearly / 100}€ / ${response.maxAmount / 100}€ maximum`, life: 10000});
        }
        this.$toast.add({severity:'success', summary:'Succès', detail:'Dépense sauvegardée et assignée', life: 3000});
        this.$emit('reload')
        this.$emit('close')
      }
    },
    async removeDepense () {
      this.$confirm.require({
        header: 'Supprimer dépense',
        message: 'Êtes-vous sûr de vouloir supprimer cette dépense ?',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$store.state.misc.loading = true
          await this.$store.dispatch('depense/deleteDepense', this.depense)
          this.$store.state.misc.loading = false
          this.$toast.add({severity:'success', summary:'Succès', detail:'Dépense supprimée', life: 3000});
          this.$emit('reload')
          this.$emit('close')
          this.$confirm.close()
        },
        reject: () => {
          this.$confirm.close()
        }
      })
    },
    async saveDepense (quitAfter = true) {

      if (this.refacturable && !this.mission) {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez renseigner une mission', life: 3000})
        return
      }

      if (!this.ocrResult[0].nature) {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez sélectionner une nature', life: 3000})
        return
      }

      this.$store.state.misc.loading = true

      const depense = {
        refacturable: this.refacturable,
        mission: this.mission?.id?? null,
        ht: Math.round(this.ocrResult[0].data.amountexvat * 100),
        tva: Math.round(this.ocrResult[0].data.vatamount * 100),
        ttc: Math.round(this.ocrResult[0].data.amount * 100),
        nature: this.ocrResult[0].nature,
        date: this.ocrResult[0].data.date,
        independent: this.independent.id,
        item: [],
        independentComment: this.commentaire
      }

      if (this.file?.files[0] || this.justificatifLocal) {
        const f = this.file?.files[0]?? this.justificatifLocal
        const form = new FormData()
        form.append('filename', f.name)
        form.append('file', f, f.name)
        const uploadedFile = await this.$store.dispatch('file/uploadFile', form)
        depense['justificatif'] = uploadedFile.id
      }

      this.ocrResult[0].children.forEach((child) => {
        depense.item.push({
          nom: child.data.nature,
          ht: Math.round(child.data.amountexvat * 100),
          tva: Math.round(child.data.vatamount * 100),
          ttc: Math.round(child.data.amount * 100)
        })
      })

      if (quitAfter) depense.motifRefus = ''

      if (this.depense?.id) {
        depense['id'] = this.depense.id
        await this.$store.dispatch('depense/updateDepense', depense)
      } else {
        await this.$store.dispatch('depense/createDepense', depense)
      }

      if (quitAfter) {
        this.$store.state.misc.loading = false
        this.$toast.add({severity:'success', summary:'Succès', detail:'Dépense sauvegardée', life: 3000});
        this.$emit('reload')
        this.$emit('close')
      }
    },
    updateSomme (attribute, key, event) {
      if (key.indexOf('-') !== -1) {
        const parent = this.ocrResult[key.split('-')[0]]
        parent.children[key.split('-')[1]].data[attribute] = event.value
        let somme = 0
        parent.children.forEach((child) => {
          somme = (somme * 100 + child.data[attribute] * 100) / 100
        })
        parent.data[attribute] = somme
      } else {
        const parent = this.ocrResult[key]
        parent.data[attribute] = event.value
      }
      this.updateTTCs()
    },
    updateTTCs () {
      this.ocrResult.forEach((parent) => {
        if (parent.children.length) {
          parent.children.forEach((child) => {
            child.data['amount'] = (child.data['amountexvat'] * 100 + child.data['vatamount'] * 100) / 100
          })
        }
        parent.data['amount'] = (parent.data['amountexvat'] * 100 + parent.data['vatamount'] * 100) / 100
      })
    },
    formatEuro (value) {
      return getEuroFormat(value)
    },
    async reviewDepense (file) {
      this.$store.state.misc.loading = true
      let transformToPdf = true
      if (typeof file === 'string') {
        const result = await this.$store.dispatch('file/downloadFile', {id: file})
        if (result.mimetype.includes('image')) transformToPdf = false
        file = new Blob([new Uint8Array(result.data.data)])
      }
      const reader = new FileReader()
      reader.onload = () => {
        this.imgSrc = reader.result
        if (transformToPdf) {
          this.imgSrc = this.imgSrc.replace('application/octet-stream', 'application/pdf')
        }
        this.dialogReview = true
        this.$store.state.misc.loading = false
      }
      reader.readAsDataURL(file)

    },
  }
}
</script>

<style>
.dialog-content-loading {
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
::v-deep(.p-dialog) {
  box-shadow: none !important;
}
</style>
