<template>
  <DataTable ref="dt" :value="ndfs" :paginator="true" class="p-datatable-customers" :rows="constants.defaultRowNumber" selection-mode="single"
             dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" :loading="loading"
             paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
             :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.data')})"
             scrollable scroll-height="400px" @rowSelect="clickNdf">
    <template #header>
      <div class="p-d-flex flex justify-content-between ml-2">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
          </span>
        <div v-if="type === constants.NDF_TYPE.IKM">
          <Button class="mr-3" type="button" label="Mes trajets" icon="pi pi-directions" @click="dialogTrajets = true" />
          <Button class="mr-3" type="button" label="Mes véhicules" icon="pi pi-car" @click="dialogVehicles = true" />
          <Button type="button" label="Nouvelle IKM" icon="pi pi-plus" @click="dialogNewIkm = true" />
        </div>
        <div v-else>
          <FileUpload ref="upload" style="display: none" mode="basic" auto custom-upload @uploader="onUpload" :maxFileSize="100000000" />
          <Button type="button" label="Nouveau" icon="pi pi-plus" @click="toggleMenu" />
          <Menu ref="menu" :model="itemsNouvelleDepense" :popup="true" />
        </div>
      </div>
    </template>
    <template #empty>
      {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
    </template>
    <template #loading>
      {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
    </template>
    <Column field="date" :header="$t('date').toUpperCase()" sortable filterField="date" sortField="date" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
      <template #body="{data}">
        {{formatDate(data.date)}}
      </template>
      <template #filter="{filterModel, filterCallback}">
        <div class="p-mb-3 p-text-bold mb-3">{{ $t('date') }}</div>
        <Calendar @date-select="filterCallback()" v-model="filterModel.value" view="month" dateFormat="mm/yy" placeholder="dd/yyyy" />
      </template>
    </Column>
    <Column field="ttc" :header="$t('montant').toUpperCase()" sortable sortField="ttc">
      <template #body="{data}">
        {{ getEuroFormat(data.ttc) }}
      </template>
    </Column>
    <Column field="combinedStatus" :header="$t('status').toUpperCase()" sortable filterField="combinedStatus" sortField="combinedStatus" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
      <template #body="{data}">
        {{ getNDFStatus(data) }}
      </template>
      <template #filter="{filterModel, filterCallback}">
        <div class="p-mb-3 p-text-bold mb-3">{{ $t('status') }}</div>
        <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="[...commonFilter('independentStatus'), ...(commonFilter('adminStatus').filter(s => s.value !== 'validate'))]" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
          <template #option="slotProps">
            <div>
              <span>{{slotProps.option.value === 'draft' ? 'Brouillon' : slotProps.option.value === 'validated' ? 'Envoyée' : slotProps.option.value === 'refund' ? 'Traitée' : 'Remboursée' }}</span>
            </div>
          </template>
          <template #value="slotProps">
            <div>
              <span>{{slotProps.value === 'draft' ? 'Brouillon' : 'Envoyée' }}</span>
            </div>
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column field="dateValidation" :header="$t('dateValidation').toUpperCase()" sortable>
      <template #body="{data}">
        {{ data.dateValidation ? new Date(data.dateValidation).toLocaleDateString() : 'Pas encore validée' }}
      </template>
    </Column>
    <Column field="dateRemboursement" :header="$t('dateRemboursement').toUpperCase()" sortable>
      <template #body="{data}">
        {{ data.independentStatus === 'draft' ? 'Pas encore envoyée' : data.dateRemboursement ? new Date(data.dateRemboursement).toLocaleDateString() : 'Pas encore remboursée' }}
      </template>
    </Column>
    <Column>
      <template #body="{data}">
        <Button v-if="data.motifRefus && data.motifRefus !== ''" icon="pi pi-exclamation-triangle" class="p-button-danger" @click="dialogMotif = true; currentNdf = data"/>
      </template>
    </Column>
  </DataTable>
  <Dialog :dismissable-mask="true" header="Motif de refus" v-model:visible="dialogMotif" :style="{width: '30vw'}" :modal="true">
    <p>
      {{ currentNdf.motifRefus }}
    </p>
  </Dialog>
  <DialogDepense v-if="dialogNewDepense" :type="type" :dialog="dialogNewDepense" :file="facture" :ocr="ocrResult"
                 :depense="currentDepense" :missions="missions" @reload="loadData" @close="dialogNewDepense = false"/>
  <DialogIkm v-if="dialogNewIkm" :dialog="dialogNewIkm" :ikm="currentIkm" :missions="missions"
             @reload="loadData" @close="dialogNewIkm = false"/>
  <DialogVehicles v-if="dialogVehicles" :dialog="dialogVehicles" @reload="loadData" @close="dialogVehicles = false"/>
  <DialogTrajets v-if="dialogTrajets" :dialog="dialogTrajets" @reload="loadData" @close="dialogTrajets = false"/>
  <dialog-n-d-f-porte :key="keyDialog" :dialog="dialogNdf" :ndf="selectedNdf" @reload="$emit('reload')" @close="dialogNdf = false"/>
</template>

<script>
import DialogDepense from "@/components/NDF/DialogDepense";
import DialogNDFPorte from "@/components/NDF/DialogNDFPorte";
import {FilterMatchMode} from "primevue/api";
import * as Money from "@/utils/Money";
import {FilterMatchModeCustom} from "@/utils/FiltersRegistration";
import DialogIkm from "@/components/NDF/DialogIkm";
import DialogVehicles from "@/components/NDF/DialogVehicles";
import DialogTrajets from "@/components/NDF/DialogTrajets";
import {mapState} from "vuex";

export default {
  name: "TableauNDFPorte",
  props: ['ndfs', 'type', 'showDates', 'missions'],
  emits: ["reload"],
  components: {DialogTrajets, DialogVehicles, DialogIkm, DialogNDFPorte, DialogDepense},
  data () {
    return {
      itemsNouvelleDepense: [
        {
          label: 'Facture',
          icon: 'pi pi-file',
          command: () => {
            this.$refs.upload.choose()
          }
        },
        {
          label: 'Saisir',
          icon: 'pi pi-pencil',
          command: () => {
            this.currentDepense = null
            this.ocrResult = null
            this.facture = null
            this.dialogNewDepense = true
          }
        },
      ],
      filters: {
        'global': {value: null, matchMode: null},
        'last_name': {value: null, matchMode: FilterMatchMode.IN},
        'first_name': {value: null, matchMode: FilterMatchMode.IN},
        'status': {value: null, matchMode: FilterMatchMode.IN},
        'combinedStatus': {value: null, matchMode: FilterMatchMode.IN},
        'date': {value: null, matchMode: FilterMatchModeCustom.DATE_AFTER_INCLUDED}
      },
      getEuroFormat: Money.getEuroFormat,
      loading: false,
      exportSheetLoading: false,
      selectedNdf: null,
      dialogNdf: false,
      keyDialog: 0,
      dialogMotif: false,
      currentNdf: null,
      dialogNewDepense: false,
      facture: null,
      ocrResult: null,
      currentDepense: null,
      dialogNewIkm: false,
      currentIkm: null,
      dialogVehicles: false,
      dialogTrajets: false
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    })
  },
  methods: {
    getNDFStatus (ndf) {
      return ndf.independentStatus === 'draft' ? 'Brouillon' : ndf.adminStatus === 'validate' ? 'Envoyée' : ndf.adminStatus === 'refund' ? 'Traitée' : 'Remboursée'
    },
    commonFilter (field) {
      let array = this.ndfs.map((s) => {
        let fields = field.split('.')
        let v = s
        fields.forEach((f) => {
          v = v[f]
        })
        return v
      })
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item || Math.abs(fa.value - item) === 0)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    loadData () {
      this.$emit('reload')
    },
    clickNdf (ndf) {
      this.selectedNdf = ndf.data
      this.dialogNdf = true
      this.keyDialog++
    },
    pushTo (name) {
      this.$router.push({'name': name})
    },
    formatDate(value) {
      if (!value) return
      if (typeof value === 'string') {
        return value
      }
      return value.toLocaleDateString('fr-FR', {
        month: '2-digit',
        year: 'numeric',
      });
    },
    formatStatus(value) {
      switch (value) {
        case 'export':
          return 'A exporter'
        case 'exported':
          return 'Exporté'
        case 'validate':
          return 'A valider'
      }
      return ''
    },
    async onUpload (file) {
      this.$store.state.misc.loading = true
      // await Promise.all([new Promise((resolve) => setTimeout(() => {resolve()}, 2000))])
      const f = file.files[0]
      const form = new FormData()
      form.append('filename', f.name)
      form.append('file', f, f.name)
      const ocr = await this.$store.dispatch('klippa/getOcr', form)
      this.$store.state.misc.loading = false
      this.facture = file
      if (ocr.data) this.ocrResult = ocr
      else {
        this.currentDepense = null
        this.ocrResult = null
      }
      this.dialogNewDepense = true
    },
    toggleMenu (event) {
      this.$refs.menu.toggle(event)
    },
  }
}
</script>

<style scoped>

</style>


