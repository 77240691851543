<template>
  <div class="flex-container non-scrollable">
    <div v-if="independent.status !== null && independent.status !== constants.INDEPENDENT_STATUS.DRAFT" class="scrollable">
      <page-title value="Notes de frais"/>
      <div class="mb-3"></div>
      <tableau-n-d-f-porte :type="constants.NDF_TYPE.NDF" :missions="missions"
                           :ndfs="ndfs.filter(n => n.type === constants.NDF_TYPE.NDF || n.type === constants.NDF_TYPE.NDFR)"
                           @reload="loadNDFS()"/>
      <div class="mb-3"></div>
      <page-title value="Dispositifs sociaux"/>
      <div class="mb-3"></div>
      <tableau-n-d-f-porte :type="constants.NDF_TYPE.SOCIAL_MEASURES" :missions="missions" :ndfs="ndfs.filter(n => n.type === constants.NDF_TYPE.SOCIAL_MEASURES)" @reload="loadNDFS()"/>
      <div class="mb-3"></div>
      <page-title value="Indemnités kilométriques"/>
      <div class="mb-3"></div>
      <tableau-n-d-f-porte :type="constants.NDF_TYPE.IKM" :ndfs="ndfs.filter(n => n.type === constants.NDF_TYPE.IKM)" @reload="loadNDFS()"/>
      <div class="mb-3"></div>
      <page-title value="Factures fournisseur"/>
      <div class="mb-3"></div>
      <tableau-facture-fournisseur-porte :missions="missions" :facturefournisseurs="facturesfournisseurs" @reload="loadFacturesFournisseurs"/>
      <div class="spacer"/>
    </div>
    <div v-else>
      Votre contrat doit être signé pour commencer à envoyer des notes de frais
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import TableauNDFPorte from "@/components/NDF/TableauNDFPorte";
import PageTitle from "@/components/PageTitle.vue";
import TableauFactureFournisseurPorte from "@/components/NDF/TableauFactureFournisseurPorte";

export default {
  name: "NDFPorte",
  components: {TableauFactureFournisseurPorte, PageTitle, TableauNDFPorte},
  data () {
    return {
      dialog: false,
      ndfs: [],
      depenses: [],
      ikms: [],
      missions: [],
      facturesfournisseurs: [],
      tab: 0
    }
  },
  computed: {
    ...mapState({
      settingsNdf: state => state.misc.settings.NDF,
      sideContent: state => state.sidebar.sideContent,
      isLogged: state => state.auth.isLoggedIn,
      constants: state => state.constants,
    }),
    ...mapGetters({
      me: 'auth/getMe',
      independent: 'independent/getIndependent'
    })
  },
  async created() {
    if (this.isLogged) {
      await this.loadData()
    }
  },
  methods: {
    async loadNDFS () {
      this.$store.state.misc.loading = true
      this.ndfs = await this.$store.dispatch('ndf/getAllNDFSByIndependent', this.independent.id)
      this.$store.state.misc.loading = false
    },
    async loadDepenses () {
      this.$store.state.misc.loading = true
      this.depenses = await this.$store.dispatch('depense/getAllDepensesByIndependent', this.independent.id)
      this.$store.state.misc.loading = false
    },
    async loadIKMS () {
      this.$store.state.misc.loading = true
      this.ikms = await this.$store.dispatch('ikm/getAllIkmsByIndependent', this.independent.id)
      this.$store.state.misc.loading = false
    },
    async loadFacturesFournisseurs () {
      this.$store.state.misc.loading = true
      this.facturesfournisseurs = await this.$store.dispatch('facturefournisseur/getAllFacturefournisseursByIndependent', this.independent.id)
      this.$store.state.misc.loading = false
    },
    async loadData () {
      this.$store.state.misc.loading = true
      await this.$store.dispatch('independent/getOneIndependentByUser', this.me.id)
      this.missions = await this.$store.dispatch('mission/getMissionsByIndependent', this.independent)
      this.loadNDFS()
      this.loadDepenses()
      this.loadIKMS()
      this.loadFacturesFournisseurs()
      this.$store.state.misc.loading = false
    },
  }
}
</script>

<style scoped>

</style>

<style>
</style>
