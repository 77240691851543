<template>
  <div>
    <Dialog @hide="$emit('close')" :draggable="false" v-model:visible="showDialogLocal" :style="{width: '40%'}" header="Mes trajets" :modal="true" class="p-fluid">
      <DataTable :value="trajets" responsiveLayout="scroll" class="p-datatable-sm">
        <Column field="name" header="Nom du trajet"></Column>
        <Column header="Actions">
          <template #body="value">
            <div class="flex">
              <Button type="button" icon="pi pi-pencil" class="p-button-success" @click="currentTrajet = value.data; trajetDialog = true" style="margin-right: .5em"></Button>
              <Button v-if="hasAccess($route.name, true)" type="button" icon="pi pi-times" class="p-button-danger" @click="removeTrajet(value.data)"></Button>
            </div>
          </template>
        </Column>
      </DataTable>
      <template #footer v-if="hasAccess($route.name, true)">
        <Button label="Ajouter un trajet" icon="pi pi-plus" class="p-button-text" @click="trajetDialog = true"/>
      </template>
    </Dialog>
    <DialogTrajet @reload="loadData" @close="close" :trajet="currentTrajet" v-if="trajetDialog" :dialog="trajetDialog"></DialogTrajet>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DialogTrajet from "@/components/NDF/DialogTrajet";
import { hasAccess } from "@/utils/Misc";

export default {
  name: "DialogTrajets",
  components: {DialogTrajet},
  emits: ["close"],
  props: ['dialog'],
  data () {
    return {
      hasAccess: hasAccess,
      showDialogLocal: this.dialog,
      trajetDialog: false,
      trajets: [],
      currentTrajet: null
    }
  },
  watch: {
    dialog (val) {
      this.showDialogLocal = val
    },
  },
  computed: {
    ...mapGetters({
      independent: 'independent/getIndependent',
    })
  },
  created () {
    this.loadData()
  },
  methods: {
    close () {
      this.trajetDialog = false
      this.currentTrajet = null
    },
    async loadData () {
      this.$store.state.misc.loading = true
      this.trajets = await this.$store.dispatch('trajet/getAllTrajetsByIndependent', this.independent.id)
      this.$store.state.misc.loading = false
    },
    async removeTrajet (trajet) {
      this.$confirm.require({
        header: 'Supprimer trajet',
        message: 'Êtes-vous sûr de vouloir supprimer ce trajet ?',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$store.state.misc.loading = true
          await this.$store.dispatch('trajet/deleteTrajet', trajet)
          this.$store.state.misc.loading = false
          this.$toast.add({severity:'success', summary:'Succès', detail:'Trajet supprimé', life: 3000});
          await this.loadData()
          this.$confirm.close()
        },
        reject: () => {

        }
      })
    },
  }
}
</script>

<style>
.dialog-content-loading {
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
::v-deep(.p-dialog) {
  box-shadow: none !important;
}
</style>
