<template>
  <div>
    <Dialog :draggable="false" v-model:visible="showDialogLocal"
            :style="{width: '70%', transition: 'left 0.5s cubic-bezier(0.51, -0.01, 0, 0.94) 0s', position: 'absolute', left: dialogReview ? '1%' : '15%'}" :header="ikm.length ? 'Indemnités kilométriques' : 'Note de frais'" :modal="true" class="p-fluid">
      <div class="flex mt-5 mb-3 align-items-center">
        <div class="flex align-items-center mr-5" v-if="ndf.type === constants.NDF_TYPE.NDFR || ndf.mission">
          <label for="refacturable" class="mr-4 font-bold">Refacturable client</label>
          <div>
            <Checkbox id="refacturable" :disabled="true" :binary="true" v-model="refacturable"/>
          </div>
        </div>
        <div class="flex align-items-center mr-5" v-if="refacturable && (ndf.type === constants.NDF_TYPE.NDFR || ndf.mission)">
          <label for="mission" class="mr-3">Mission</label>
          <InputText id="mission" disabled :model-value="ndf.mission.clientContract.name" required="true" />
        </div>
        <div class="flex align-items-center mr-5">
          <label for="libelle" class="mr-3">Libellé</label>
          <InputText id="libelle" v-model="libelle" disabled/>
        </div>
        <div class="flex align-items-center">
          <label for="date" class="mr-3">Date</label>
          <Calendar id="date" :disabled="ndf.independentStatus === 'validated'" :showIcon="true" date-format="mm/yy" view="month" v-model="date" required="true" />
        </div>
      </div>
<!--      <div class="flex justify-content-center mt-5 mb-5">-->
<!--        <FileUpload mode="basic" choose-label="Nouvelle dépense" auto custom-upload @uploader="getocr" accept="image/*" :maxFileSize="10000000" />-->
<!--      </div>-->

      <div v-if="ocrResult.length">
        <TreeTable :value="ocrResult">
          <template #header>
            Dépenses
          </template>
          <Column class="flex align-items-center" field="nature" header="Nature" :expander="true">
            <template #body="value">
              <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1" style="max-width: 8vw">
<!--                <Dropdown v-model="value.node.nature" :options="natures"></Dropdown>-->
                {{ value.node.nature.label }}
              </div>
              <div v-else style="max-width: 8vw">
                {{ value.node.data.nature }}
              </div>
            </template>
          </Column>
          <Column field="amountexvat" header="Prix HT">
            <template #body="value">
              <InputNumber disabled v-model="value.node.data.amountexvat" locale="fr-FR" mode="currency" currency="EUR"></InputNumber>
            </template>
          </Column>
          <Column field="vatamount" header="TVA">
            <template #body="value">
              <InputNumber disabled v-model="value.node.data.vatamount" locale="fr-FR" mode="currency" currency="EUR"></InputNumber>
            </template>
          </Column>
          <Column field="amount" header="Prix TTC">
            <template #body="value">
              <InputNumber disabled :model-value="value.node.data.amountexvat + value.node.data.vatamount" locale="fr-FR" mode="currency" currency="EUR" />
            </template>
          </Column>
          <Column field="date" header="Date">
            <template #body="value">
              <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1">
                <Calendar :disabled="ndf?.independentStatus !== 'draft'" :showIcon="true" v-model="value.node.data.date" date-format="dd/mm/yy"></Calendar>
              </div>
            </template>
          </Column>
          <Column field="file" header="Actions">
            <template #body="value">
              <div class="flex" v-if="value.node.key.toString().indexOf('-') === -1">
                <Button v-tooltip.top="'Voir le justificatif'" type="button" v-if="value.node.justificatif" icon="pi pi-eye" class="p-button-success" @click="reviewDepense(value.node.justificatif)" style="margin-right: .5em"></Button>
                <Button v-tooltip.top="value.node.justificatif ? 'Remplacer le justificatif' : 'Ajouter un justificatif'" type="button" icon="pi pi-upload" class="p-button-info" v-if="ndf?.independentStatus === 'draft'" @click="$refs['upload' + value.node.id].choose()" style="margin-right: .5em"></Button>
                <FileUpload :ref="'upload' + value.node.id" style="display: none" mode="basic" auto custom-upload @uploader="(file) => {onUploadJustificatif(file, value.node)}" accept="image/png, image/jpeg, application/pdf" :maxFileSize="10000000" />
                <Button v-tooltip.top="'Supprimer la dépense'" type="button" v-if="hasAccess($route.name, true) && ndf?.independentStatus === 'draft'" icon="pi pi-times" class="p-button-danger mr-2" @click="removeDepense(value)"></Button>
                <Button v-tooltip.top="'Commentaires'" type="button" icon="pi pi-info" class="p-button-success" @click="clickCommentairesDepense(value.node)"></Button>
              </div>
            </template>
          </Column>
        </TreeTable>
      </div>
      <div v-else-if="ikm.length !== 0">
        <DataTable :value="ikm">
          <template #header>
            IKM
          </template>
          <template #empty>
            {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
          </template>
          <template #loading>
            {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
          </template>
          <Column field="vehicle.name" :header="$t('vehicle').toUpperCase()">
          </Column>
          <Column field="distanceTrajet" :header="$t('distanceTrajet').toUpperCase()">
            <template #body="data">
              <span>{{ data.data.distanceTrajet }} km</span>
            </template>
          </Column>
          <Column field="nombreTrajets" :header="$t('nombreTrajets').toUpperCase()">
          </Column>
          <Column field="distanceTotale" :header="$t('distanceTotale').toUpperCase()">
            <template #body="data">
              <span>{{ data.data.distanceTotale.toFixed(2) }} km</span>
            </template>
          </Column>
          <Column field="price" :header="$t('price').toUpperCase()">
            <template #body="data">
              <span>{{ getEuroFormat(data.data.price) }}</span>
            </template>
          </Column>
          <Column field="date" :header="$t('date').toUpperCase()">
            <template #body="{data}">
              <span v-for="(date, index) in data.dates" :key="index"> {{ getDateFormated(date) }}{{ data.dates.length - 1 === index ? '' : ', ' }}</span>
            </template>
          </Column>
          <Column field="file" header="Actions">
            <template #body="value">
              <div class="flex">
                <Button type="button" v-if="hasAccess($route.name, true) && ndf?.independentStatus === 'draft'" icon="pi pi-times" class="p-button-danger" @click="removeIkm(value)"></Button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="col-12 mt-5" v-if="ikm.length === 0">
        <div class="card widget-boxes p-0 grid grid-nogutter">
          <div class="col-12 md:col-6 lg:col-4 py-2 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Total HT</span>
            </div>
            <span class="block font-bold text-5xl mb-3">{{ getEuroFormat(totalhtComputed * 100) }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-4 py-2 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">TVA</span>
            </div>
            <span class="block font-bold text-5xl mb-3">{{ getEuroFormat(totaltaxesComputed * 100) }}</span>
          </div>
          <div class="col-12 md:col-6 lg:col-4 py-2 px-6 border-none">
            <div class="mb-3">
              <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Total TTC</span>
            </div>
            <span class="block font-bold text-5xl mb-3">{{ getEuroFormat(totalttcComputed * 100) }}</span>
          </div>
        </div>
      </div>

      <div class="col-6 mt-5" v-else style="margin: auto;">
        <div class="card widget-boxes p-0 grid grid-nogutter justify-content-center">
          <div class="col-6 md:col-4 py-2 px-6 border-none">
            <div class="mb-3">
              <Avatar icon="pi pi-money-bill" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Prix total</span>
            </div>
            <span class="block font-bold text-5xl mb-3">{{ getEuroFormat(totalpriceComputed) }}</span>
          </div>
        </div>
      </div>

      <template #footer v-if="ndf?.independentStatus === 'draft' && hasAccess($route.name, true)">
        <Button v-if="ikm.length === 0" label="Enregistrer le brouillon" icon="pi pi-check" class="p-button-text" @click="saveNDF" />
        <Button :label="'Envoyer ' + (ikm.length ? 'l\'IKM' :  'la note de frais')" icon="pi pi-check" class="p-button-text" @click="validateNDF" />
      </template>
    </Dialog>
    <Dialog v-if="currentDepense" header="Commentaires" v-model:visible="dialogCommentaires" :style="{width: '25%'}" :modal="true">
      <div class="p-float-label mb-4 mt-4">
        <Textarea id="commentairePorte" style="width: 100%; border-radius: 10px" :autoResize="true" rows="3" v-model="currentDepense.independentComment"/>
        <label for="commentairePorte">Commentaire porté</label>
      </div>
      <div v-if="currentDepense.adminComment" class="p-float-label">
        <Textarea id="commentaireAdmin" style="width: 100%; border-radius: 10px" :autoResize="true" rows="3" v-model="currentDepense.adminComment" :disabled="true"/>
        <label for="commentaireAdmin">Commentaire gestionnaire</label>
      </div>
      <template #footer>
        <Button label="Fermer" icon="pi pi-times" class="p-button-danger" @click="dialogCommentaires = false; currentDepense = null" autofocus />
        <Button label="Sauvegarder" icon="pi pi-check" @click="saveCommentaire" autofocus />
      </template>
    </Dialog>
    <Dialog v-model:visible="dialogReview" position="right" :style="{width: '25%'}" dismissable-mask class="p-fluid">
      <iframe v-if="imgSrc.includes('application/pdf')" style="width: 100%; height: 50vh" :src="imgSrc + '#toolbar=0&navpanes=0&scrollbar=0'" />
      <img v-else style="width: 100%" :src="imgSrc" />
    </Dialog>
  </div>
</template>

<script>
import {getEuroFormat} from "@/utils/Money";
import {getDateFormatted} from "@/utils/Date";
import {capitalizeWord, getOptionLabel, hasAccess} from "@/utils/Misc";
import {mapState} from "vuex";

export default {
  name: "DialogNDFPorte",
  emits: ["close", "reload"],
  props: ['dialog', 'ndf', 'type'],
  data () {
    return {
      hasAccess: hasAccess,
      capitalizeWord: capitalizeWord,
      getEuroFormat: getEuroFormat,
      getDateFormated: getDateFormatted,
      showDialogLocal: this.dialog,
      date: new Date(),
      currentDepense: null,
      dialogCommentaires: false,
      dialogReview: false,
      refacturable: false,
      ocrResult: [],
      ikm: [],
      libelle: '',
      natures: ['Indemnités kilométriques', 'Achats fournitures', 'Culture', 'Loisirs et sport', 'Vacances', 'PERCO'],
      imgSrc: null
    }
  },
  watch: {
    dialog (val) {
      this.showDialogLocal = val
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    }),
    totalhtComputed () {
      let somme = 0
      this.ocrResult.forEach((data) => {
        somme = (somme * 100 + data.data.amountexvat * 100) / 100
      })
      return somme
    },
    totaltaxesComputed () {
      let somme = 0
      this.ocrResult.forEach((data) => {
        somme = (somme * 100 + data.data.vatamount * 100) / 100
      })
      return somme
    },
    totalttcComputed () {
      return (this.totalhtComputed * 100 + this.totaltaxesComputed * 100) / 100
    },
    totalpriceComputed () {
      let somme = 0
      this.ikm.forEach((ikm) => {
        somme = (somme * 100 + ikm.price * 100) / 100
      })
      return somme
    }
  },
  mounted() {
    if (this.ndf) {
      this.date = this.ndf.date
      this.libelle = getOptionLabel(this.constants.data.ndfTypeOptions, this.ndf.type)
      this.refacturable = this.ndf.mission != null
      if (this.ndf.depenses.length !== 0) {
        this.ndf.depenses.forEach((depense, key) => {
          const data = { amount: depense.ttc, amountexvat: depense.ht, vatamount: depense.tva, date: depense.date }
          this.ocrResult.push({ id: depense.id, 'key': key, data: data, nature: depense.nature, children: [], adminComment: depense.adminComment, independentComment: depense.independentComment, 'justificatif': depense.justificatif, editable: false })
          depense.item.forEach((i, index) => {
            this.ocrResult[key].children.push({ key: key + '-' + index, data: { nature: i.nom, amount: i.ttc, amountexvat: i.ht, vatamount: i.tva } })
          })
        })
      } else if (this.ndf.ikm.length !== 0) {
        this.ikm = this.ndf.ikm
      }
    }
  },
  methods: {
    clickCommentairesDepense (depense) {
      this.currentDepense = depense
      this.dialogCommentaires = true
    },
    onUploadJustificatif (file, depense) {
      depense.newJustificatif = file.files[0]
      depense.justificatif = depense.newJustificatif
      this.reviewDepense(depense.newJustificatif)
    },
    async saveCommentaire () {
      this.$store.state.misc.loading = true
      const data = {
        id: this.currentDepense.id,
        independentComment: this.currentDepense.independentComment
      }
      await this.$store.dispatch('depense/updateDepense', data)
      this.$toast.add({severity:'success', summary:'Succès', detail:'Commentaire enregistré', life: 3000});
      this.dialogCommentaires = false
      this.currentDepense = null
      this.$store.state.misc.loading = false
    },
    async saveNDF () {
      this.$store.state.misc.loading = true
      const depenses = []
      for (const dep of this.ocrResult) {
        const depData = {
          id: dep.id,
          date: dep.data.date
        }
        if (dep.newJustificatif) {
          const f = dep.newJustificatif
          const form = new FormData()
          form.append('filename', f.name)
          form.append('file', f, f.name)
          const uploadedFile = await this.$store.dispatch('file/uploadFile', form)
          depData['justificatif'] = uploadedFile.id
        }
        depenses.push(depData)
      }
      const data = {
        id: this.ndf.id,
        depenses,
        date: this.date
      }
      await this.$store.dispatch('ndf/updateNDF', data)
      this.dialogReview = false
      this.$emit('reload')
      this.$emit('close')
      this.$store.state.misc.loading = false
    },
    async validateNDF () {
      this.$store.state.misc.loading = true
      const data = { id: this.ndf.id, independentStatus: 'validated', motifRefus: '', date: this.date }
      if (this.ikm.length === 0) await this.saveNDF()
      await this.$store.dispatch('ndf/updateNDF', data)
      this.$toast.add({severity:'success', summary:'Succès', detail:'Note de frais envoyée', life: 3000});
      this.dialogReview = false
      this.$emit('reload')
      this.$emit('close')
      this.$store.state.misc.loading = false
    },
    updateSomme (attribute, key, event) {
      if (key.indexOf('-') !== -1) {
        const parent = this.ocrResult[key.split('-')[0]]
        parent.children[key.split('-')[1]].data[attribute] = event.value
        let somme = 0
        parent.children.forEach((child) => {
          somme = (somme * 100 + child.data[attribute] * 100) / 100
        })
        parent.data[attribute] = somme
      }
      this.updateTTCs()
    },
    updateTTCs () {
      this.ocrResult.forEach((parent) => {
        if (parent.children.length) {
          parent.children.forEach((child) => {
            child.data['amount'] = (child.data['amountexvat'] * 100 + child.data['vatamount'] * 100) / 100
          })
        }
        parent.data['amount'] = (parent.data['amountexvat'] * 100 + parent.data['vatamount'] * 100) / 100
      })
    },
    async reviewDepense (file) {
      this.$store.state.misc.loading = true
      let transformToPdf = true
      if (typeof file === 'string') {
        const result = await this.$store.dispatch('file/downloadFile', {id: file})
        if (result.mimetype.includes('image')) transformToPdf = false
        file = new Blob([new Uint8Array(result.data.data)])
      }
      const reader = new FileReader()
      reader.onload = () => {
        this.imgSrc = reader.result
        if (transformToPdf) {
          this.imgSrc = this.imgSrc.replace('application/octet-stream', 'application/pdf')
        }
        this.dialogReview = true
        this.$store.state.misc.loading = false
      }
      reader.readAsDataURL(file)

    },
    async removeDepense (value) {
      this.$confirm.require({
        header: 'Supprimer dépense',
        message: 'Êtes-vous sûr de vouloir supprimer cette dépense de la note de frais ?',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$store.state.misc.loading = true
          this.ocrResult = this.ocrResult.filter(data => data !== value.node)
          const dep = this.ndf.depenses.find(d => d.id === value.node.id)
          await this.$store.dispatch('depense/unassignDepense', dep)
          await this.$store.dispatch('depense/deleteDepense', dep)
          this.$toast.add({severity:'success', summary:'Succès', detail:'Dépense supprimée', life: 3000});
          if (this.ocrResult.length === 0) {
            this.$emit('close')
          }
          this.$emit('reload')
          this.$store.state.misc.loading = false
          this.$confirm.close()
        },
      })
    },
    async removeIkm (value) {
      this.$confirm.require({
        header: 'Supprimer IKM',
        message: 'Êtes-vous sûr de vouloir supprimer cette IKM de la note de frais ?',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$store.state.misc.loading = true
          this.ikm = this.ikm.filter(data => data !== value.data)
          const ikm = this.ndf.ikm.find(i => i.id === value.data.id)
          await this.$store.dispatch('ikm/unassignIkm', ikm)
          await this.$store.dispatch('ikm/deleteIkm', ikm)
          if (this.ikm.length === 0) {
            this.$emit('close')
          }
          this.$toast.add({severity:'success', summary:'Succès', detail:'IKM supprimée', life: 3000});
          this.$emit('reload')
          this.$store.state.misc.loading = false
          this.$confirm.close()
        },
        reject: () => {

        }
      })
    },
    getocr (file) {
      const result = JSON.parse(JSON.stringify(this.jsonTest))
      const data = { amount: result.data.amount / 100, amountexvat: result.data.amountexvat / 100, vatamount: result.data.vatamount / 100, date: getDateFormatted(new Date(result.data.date)) }
      const key = this.ocrResult.length
      this.ocrResult.push({ 'key': key, data: data, nature: '', children: [], 'justificatif': file.files[0], editable: true })

      result.data.vatitems.forEach((vat, index) => {
        this.ocrResult[key].editable = false
        this.ocrResult[key].children.push({ key: key + '-' + index, data: { nature: 'TVA ' + (index + 1), amount: vat.amount_incl_vat / 100, amountexvat: vat.amount_excl_vat / 100, vatamount: vat.amount / 100 } })
      })

      this.reviewDepense(file.files[0])
    }
  }
}
</script>

<style>
.dialog-content-loading {
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
::v-deep(.p-dialog) {
  box-shadow: none !important;
}
</style>
